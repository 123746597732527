var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "spe-section" }, [
    _c(
      "div",
      {
        staticClass: "spe-section__topic",
        attrs: { role: "button" },
        on: { click: _vm.toggleExpanded }
      },
      [
        _c("span", [_vm._v(_vm._s(_vm.topic))]),
        _vm._v(" "),
        _c("a-icon", { attrs: { type: _vm.expanded ? "up" : "down" } })
      ],
      1
    ),
    _c(
      "div",
      { class: ["spe-section__body", _vm.expanded && "expanded"] },
      [_vm._t("default")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }