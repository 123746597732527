//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Sketch } from 'vue-color';
export default {
  components: { 'sketch-picker': Sketch },

  props: {
    color: { type: String, default: '#194d33' },
    label: { type: String, default: 'Color selection' }
  },

  data() {
    return {
      visible: false,
      colors: { hex: '#194d33' }
    };
  },

  watch: {
    'color': {
      handler(value) {
        this.colors = { hex: value };
      },
      immediate: true
    }
  },

  methods: {
    handleCancel() {
      this.visible = false;
    },
    handleChoose() {
      this.visible = false;
      const colorCode = this.colors.hex8 === '#00000000'
        ? 'transparent'
        : this.colors.hex
      this.$emit('change', colorCode);
    }
  }
};
