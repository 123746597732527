var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-popover",
    {
      attrs: { trigger: "click" },
      model: {
        value: _vm.visible,
        callback: function($$v) {
          _vm.visible = $$v
        },
        expression: "visible"
      }
    },
    [
      _c("div", { staticClass: "picker-color" }, [
        _c("div", {
          staticClass: "picker-color--demo",
          style: { "--bgcolor": _vm.color }
        }),
        _c("div", { staticClass: "picker-color--button" }, [
          _vm._v(" " + _vm._s(_vm.$t(_vm.label)) + " ")
        ])
      ]),
      _c(
        "template",
        { slot: "content" },
        [
          _c("sketch-picker", {
            model: {
              value: _vm.colors,
              callback: function($$v) {
                _vm.colors = $$v
              },
              expression: "colors"
            }
          }),
          _c(
            "div",
            { staticClass: "content-between mt-xs" },
            [
              _c(
                "a-button",
                {
                  staticStyle: { width: "100%", "margin-right": "5px" },
                  on: { click: _vm.handleCancel }
                },
                [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]
              ),
              _c(
                "a-button",
                {
                  staticStyle: { width: "100%", "margin-left": "5px" },
                  on: { click: _vm.handleChoose }
                },
                [_vm._v(" OK ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }