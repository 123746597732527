//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    topic: { type: String, required: true, default: '' },
    expanded: { type: Boolean, default: false }
  },

  methods: {
    toggleExpanded() {
      this.$emit('toggleExpanded');
    }
  }
};
